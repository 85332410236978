import * as React from "react";
import SadImage from "assets/icons/sad.svg";
import pageStyles from "./styles";

export class ErrorBoundary extends React.Component<any, any> {
  state = { hasError: false, errorInfo: "", error: "" };

  refresh() {
    window.location.reload();
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({
      error: String(error),
      errorInfo: String(errorInfo),
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={pageStyles.container}>
          <img alt="Error 404" style={pageStyles.img} src={SadImage} />
          <p style={pageStyles.text as any}>
            <b>ERROR: </b>
            {this.state.errorInfo} (CODE: {this.state.error})
          </p>
          <button onClick={this.refresh} style={pageStyles.button as any}>
            Recargar sitio web
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}
