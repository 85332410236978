interface StylesProps {
  flexColumn: any;
  flexRow: any;
  flexCenter: any;
  coverBackground: any;
  ellipsis: any;
  noEllipsis: any;
  buttonAction: any;
  defaultButton: any;
  defaultInput: any;
  centerHorizontal: any;
  centerVertical: any;
  centerAll: any;
  smallQuery: any;
  bigQuery: any;
  midQuery: any;
  returnSquare: any;
}

const styles: StylesProps = {
  flexColumn: {
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
  },
  flexRow: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  flexCenter: {
    justifyContent: "center",
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  coverBackground: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  noEllipsis: {
    whiteSpace: "initial",
    overflow: "visible",
    textOverflow: "unset",
  },
  buttonAction: {
    backgroundColor: "#787F87",
    color: "white",
    padding: "6px 24px",
    width: "fit-content",
    fontSize: 12,
    fontWeight: "bold",
    cursor: "pointer",
    border: "1px solid #787F87",
  },
  defaultButton: {
    border: "none",
    outline: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
    "&:active": {
      opacity: 0.5,
    },
  },
  defaultInput: {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
  },
  centerHorizontal: {
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
  },
  centerVertical: {
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
  },
  centerAll: {
    position: "relative",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  smallQuery: "@media(max-width: 780px)",
  bigQuery: "@media(min-width: 780px)",
  midQuery: "@media(max-width: 900px)",
  returnSquare: (value) => ({
    maxWidth: value,
    maxHeight: value,
    minWidth: value,
    minHeight: value,
  }),
};

export default styles;
