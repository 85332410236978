import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  sendSignInLinkToEmail,
  linkWithCredential,
  EmailAuthProvider,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  getDatabase,
  ref as refDatabase,
  get,
  child,
  onValue,
  orderByChild,
  equalTo,
  query,
  set,
} from "firebase/database";
import {
  getFirestore,
  collection,
  doc,
  query as queryFireStore,
  where,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

const firebaseConfig =
  (process.env.NODE_ENV === "development" ||
    window?.location?.host?.includes?.("test.")) &&
  false
    ? {
        apiKey: "AIzaSyBr0FnuSJZ1eD1Fs4SrsjWeLxwYPn7qkaQ",
        authDomain: "froin-51efe.firebaseapp.com",
        databaseURL: "https://froin-51efe-default-rtdb.firebaseio.com",
        projectId: "froin-51efe",
        storageBucket: "froin-51efe.appspot.com",
        messagingSenderId: "975732036410",
        appId: "1:975732036410:web:62a10df506eeba3521f603",
        measurementId: "G-CDSYMGGDYJ",
      }
    : {
        apiKey: "AIzaSyDrbzGr_ydgA-HxW7QC3PM77GwsbzBDAe8",
        authDomain: "froin-club.firebaseapp.com",
        databaseURL: "https://froin-club-default-rtdb.firebaseio.com",
        projectId: "froin-club",
        storageBucket: "froin-club.appspot.com",
        messagingSenderId: "785344433615",
        appId: "1:785344433615:web:130fc664e1732d2ec9d6ba",
        measurementId: "G-TJSPNKRV1D",
      };

const messagingToken =
  process.env.NODE_ENV === "development" ||
  window?.location?.host?.includes?.("test.")
    ? "QPHfEN7Zx2pXPHUHjYlNOVSLDj1Xv6E5WJOcVgMLFN4"
    : "5vR7Wp9l1EwOC3Mw0Ky1X238yc9Gpcv1fOsxShAYXHA";

const app = initializeApp(firebaseConfig);

// Analytics
const analytics = getAnalytics(app);
const analyticsService = {
  logEvent: (eventName: string, params) =>
    logEvent(analytics, eventName, params),
};

// Authentication
const auth = getAuth(app);
const actionCodeSettings = {
  url: window.location.href,
  handleCodeInApp: true,
};
const authService = {
  EmailAuthProvider: EmailAuthProvider,
  sendSignInLinkToEmail: (email: string) =>
    sendSignInLinkToEmail(auth, email, actionCodeSettings),
  onAuthStateChanged: (observer) => onAuthStateChanged(auth, observer),
  signOut: () => signOut(auth),
  currentUser: () => getAuth(app).currentUser,
  linkWithCredential: (credential) =>
    linkWithCredential(auth.currentUser, credential),
  isSignInWithEmailLink: (url: string) => isSignInWithEmailLink(auth, url),
  signInWithEmailLink: (email: string, url: string) =>
    signInWithEmailLink(auth, email, url),
};

// Firestore
const firestore = getFirestore(app);
const firestoreService = {
  setDoc: (refCollection: string, refDocument: string, data) =>
    setDoc(doc(firestore, refCollection, refDocument), data),
  updateDoc: (refCollection: string, refDocument: string, data) =>
    updateDoc(doc(firestore, refCollection, refDocument), data),
  getDoc: (refCollection: string, refDocument: string) =>
    getDoc(doc(firestore, refCollection, refDocument)),
  getCollection: (refCollection: string) =>
    getDocs(collection(firestore, refCollection)),
  getQuery: (refCollection: string, queries = []) => {
    const returnQueries = () =>
      queries.map((queryData) =>
        where(queryData.label, queryData.condition, queryData.value)
      );
    return getDocs(
      queryFireStore(collection(firestore, refCollection), ...returnQueries())
    );
  },
};

// Realt time Databse
const database = getDatabase(app);
const databaseService = {
  getDatabase: (url: string) => {
    const dbRef = refDatabase(database);
    return get(child(dbRef, url));
  },
  onDatabase: (url: string, callback) => {
    const dbRef = refDatabase(database);
    const listener = onValue(
      child(dbRef, url),
      (snapshot) => {
        callback(snapshot.val());
      },
      (snapshot) => {
        console.log("Error onDatabase: ", snapshot);
      }
    );

    return listener;
  },
  getQueryDatabase: (url: string, byChild, equalValue) => {
    const dbRef = refDatabase(database, url);
    return get(query(dbRef, orderByChild(byChild), equalTo(equalValue)));
  },
  saveDatabase: (url: string, data) => {
    const dbRef = refDatabase(database);
    return set(child(dbRef, url), data);
  },
};

// Push Notifications

const messaging = async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging();
    }
    console.log("Firebase not supported this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const messagingService = {
  getToken: () =>
    messaging()
      .then((messagingData) => {
        return getToken(messagingData, { vapidKey: messagingToken });
      })
      .then((currentToken) => {
        if (currentToken) {
          return currentToken;
        } else {
          return Notification.requestPermission();
        }
      })
      .catch((err) => {
        throw new Error(err);
      }),
};

export {
  authService,
  analyticsService,
  databaseService,
  firestoreService,
  messagingService,
};
