import * as React from "react";
import { useState } from "react";

import "./styles/RegisterWebinarFormStyles.css";
import { verifyEmail } from "utils/validations";
import { TextInput } from "components";
import Model from "hooks/Model";
import * as moment from "moment";
import Api from "utils/api";

function RegisterWebinarFormScreen() {
  const [alias, setAlias] = useState("");
  const [email, setEmail] = useState("");

  const onRegisterEmail = async (email: string) => {
    try {
      const currentTime = moment().format("x");

      Model.setData("loading", true);
      Model.setData("dialog", { open: false });

      const response = await Api.post("/manychat/registerWebinar", {
        alias,
        email,
        created: currentTime,
      });

      if (response.status === 200) {
        setAlias("");
        setEmail("");
        Model.updateAlerts({
          message:
            "Registramos tus datos éxistosamente, pronto te enviaremos un correo con los detalles de la sesión informativa.",
          variant: "success",
        });
      } else {
        throw response.data;
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || String(error),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onSubmit = () => {
    const formatedEmail = email.toLocaleLowerCase().replace(/\s/g, "");

    if (!email || !alias) {
      Model.updateAlerts({
        message: "Completa todos los datos para inscribirte",
        variant: "error",
      });
    } else if (!verifyEmail(formatedEmail)) {
      Model.updateAlerts({
        message: "Ingresa un correo válido para continuar",
        variant: "error",
      });
    } else {
      Model.setData("dialog", {
        open: true,
        title: `${alias}, Antes de continuar ...`,
        text: `¿Aceptas que utilicemos tu correo electrónico (${formatedEmail}) para compartirte información de valor sobre nuestra próxima "Sesión informativa en vivo" y los detalles del proyecto FROIN? Puedes darte de baja en cualquier momento.`,
        txtLeft: "Cancelar",
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: "Acepto",
        fnRight: () => onRegisterEmail(formatedEmail),
      });
    }
  };

  return (
    <div className="register-container">
      <h3 className="register-title">Sesión informativa en vivo</h3>
      <p className="register-description">
        🚀 ¡Sé parte del futuro del entretenimiento con FROIN! 🎤🎟️
        <br />
        <br />
        Estamos abriendo un espacio exclusivo para una charla en vivo con
        visionarios como tú. Buscamos artistas, comercios, marcas, clientes,
        inversionistas e influencers del mundo del entretenimiento que quieran
        sumarse a un proyecto revolucionario.
        <br />
        <br />
        🔥 Descubre nuestras propuestas, el futuro de FROIN y resuelve todas tus
        dudas en directo.
        <br />
        <br />⏳ ¡Cupo limitado! Pronto cerraremos las puertas a nuevas
        alianzas. No te quedes fuera, deja tu email y recibe toda la info. 💡
      </p>

      {true ? (
        <p className="blocked-register-row-submit">
          <b>El registro ha finalizado.</b>
          <br />
          Te invitamos a que estes pendiente de nuestros canales de comunicación
          (Instagram, Email y WhatsApp) donde compartiremos información de
          próximas charlas
        </p>
      ) : (
        <div className="register-row-submit">
          <TextInput
            value={alias}
            onChange={setAlias}
            placeholder="Alias"
            customClassName="input-info"
          />
          <TextInput
            customClassName="input-info"
            value={email}
            onChange={setEmail}
            placeholder="Correo"
          />
          <button className="register-btn-submit" onClick={onSubmit}>
            Inscribirme
          </button>
        </div>
      )}
    </div>
  );
}

export default RegisterWebinarFormScreen;
