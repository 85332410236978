import * as React from "react";
import { useContext, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { detectIncognito } from "detectincognitojs";

import LocalStorage from "utils/localStorage";
import { Store } from "hooks/main_store";
import Model from "hooks/Model";

import Page404Screen from "./page404";
import OlderVerificationScreen from "./older-verification";
import WelcomeScreen from "./welcome";

import HomeScreen from "./home";
import InvestScreen from "./invest";
import AccessAccountScreen from "./home/AccessAccount";

import SendScreen from "./send";
import ReceiveScreen from "./receive";
import HistoryListScreen from "./history";

import LegalScreen from "./legal/renderLegalDoc";

import ProfileVideosScreen from "./vwin/ProfileVideos";
import DetailVideoScreen from "./vwin/DetailVideo";
import LayerLoadVideoScreen from "./vwin/DetailVideo/LayerLoadVideo";
import RankingScreen from "./vwin/Ranking";

import RedeemInitialScreen from "./redeem";

export const RoutesPaths = {
  home: "/",
  invest: "/invest",
  send: "/send",
  receive: "/receive",
  history: "/history",
  accesAccount: "/access-account",
  detailVideo: "/detail-video",
  detailVideoReward: "/detail-video-reward",
  ranking: "/ranking",
  profileVideos: "/profile-videos",
  legal: "/legal",
  redeem: "/redeem",
};

const userRouter = createBrowserRouter(
  [
    {
      path: "/",
      element: <HomeScreen />,
    },
    {
      path: "/invest",
      element: <InvestScreen />,
    },
    {
      path: "/send",
      element: <SendScreen />,
    },
    {
      path: "/receive",
      element: <ReceiveScreen />,
    },
    {
      path: "/history",
      element: <HistoryListScreen />,
    },
    {
      path: "/access-account",
      element: <AccessAccountScreen />,
    },
    {
      path: "/detail-video",
      element: <LayerLoadVideoScreen />,
    },
    {
      path: "/detail-video-reward",
      element: <DetailVideoScreen />,
    },
    {
      path: "/ranking",
      element: <RankingScreen />,
    },
    {
      path: "/profile-videos",
      element: <ProfileVideosScreen />,
    },
    {
      path: "/legal",
      element: <LegalScreen />,
    },
    {
      path: "/redeem",
      element: <RedeemInitialScreen />,
    },
    {
      path: "*",
      element: <Page404Screen />,
    },
  ],
  {
    basename: "/",
  }
);

const guestRouter = createBrowserRouter(
  [
    {
      path: "/",
      element: <HomeScreen />,
    },
    {
      path: "/invest",
      element: <InvestScreen />,
    },
    {
      path: "/detail-video",
      element: <DetailVideoScreen />,
    },
    {
      path: "/detail-video-reward",
      element: <DetailVideoScreen />,
    },
    {
      path: "/profile-videos",
      element: <ProfileVideosScreen />,
    },
    {
      path: "/legal",
      element: <LegalScreen />,
    },
    {
      path: "*",
      element: <Page404Screen />,
    },
  ],
  {
    basename: "/",
  }
);

const welcomeRouter = createBrowserRouter([
  {
    path: "/",
    element: <WelcomeScreen />,
  },
  {
    path: "/invest",
    element: <InvestScreen />,
  },
  {
    path: "/profile-videos",
    element: <ProfileVideosScreen />,
  },
  {
    path: "/legal",
    element: <LegalScreen />,
  },
  {
    path: "/detail-video",
    element: <DetailVideoScreen />,
  },
  {
    path: "/detail-video-reward",
    element: <DetailVideoScreen />,
  },
  {
    path: "*",
    element: <Page404Screen />,
  },
]);

const olderVerificationRouter = createBrowserRouter(
  [
    {
      path: "*",
      element: <OlderVerificationScreen />,
    },
  ],
  {
    basename: "/",
  }
);

const Routes = () => {
  const { state } = useContext(Store);
  const urlParams = new URLSearchParams(window.location.search);
  const welcomePage = urlParams.get("page");

  const initialLoad = async () => {
    try {
      const result = await detectIncognito();

      const isOlder = LocalStorage(result.isPrivate).getItem("isOlder");
      const completedWelcome = LocalStorage(result.isPrivate).getItem(
        "completedWelcome"
      );

      if (isOlder === "true") {
        Model.setData("isOlder", true);
      }

      if (completedWelcome === "true") {
        Model.setData("completedWelcome", true);
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    }
  };

  const returnRouter = () => {
    if (state?.infoUser?.uid && !welcomePage) {
      return userRouter;
    } else if (state?.completedWelcome && !welcomePage) {
      return guestRouter;
    } else if (state?.isOlder) {
      return welcomeRouter;
    }

    return olderVerificationRouter;
  };

  useEffect(() => {
    initialLoad();
  }, []);

  return <RouterProvider router={returnRouter()} />;
};

export default Routes;
