import * as React from "react";

export interface StateProps {
  loading: boolean;
  opacity: number;
  errorLoad: boolean;
  disableScroll: boolean;
  showLogoLoader: boolean;
  initialLoadProgress: number;
  alerts: Array<any>;
  dialog: {
    open?: boolean;
    title?: string;
    text?: string;
    link?: string;
    txtAux?: string;
    fnAux?: any;
    txtLeft?: string;
    fnLeft?: any;
    txtRight?: string;
    fnRight?: any;
    onClose?: any;
  };
  infoUser: any;
  balance: number;
  showAuth: boolean;
  isOlder: boolean;
  isIncognito?: boolean;
  completedWelcome: boolean;
  version: string;
}

export interface StoreProps {
  state: StateProps;
  dispatch: React.Dispatch<any>;
}

const version = "0.5.4";

const initialState: StateProps = {
  loading: true,
  opacity: 1,
  errorLoad: false,
  disableScroll: false,
  showLogoLoader: false,
  initialLoadProgress: 0,
  alerts: [],
  dialog: {
    open: false,
  },
  infoUser: null,
  balance: 0,
  showAuth: false,
  isOlder: false,
  completedWelcome: false,
  version: version,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_STORE":
      return { ...state, [action.label]: action.payload };
    case "UPDATE_INFO_USER":
      const newInfo = {
        ...(state.infoUser || {}),
        ...action.payload,
      };
      return {
        ...state,
        infoUser: newInfo,
      };
    case "UPDATE_ALERT":
      return {
        ...state,
        alerts: [...state.alerts, action.payload],
      };
    case "RESET_DATA":
      return { ...initialState };
    default:
      return state;
  }
}

export const Store = React.createContext<StoreProps>({
  state: initialState,
  dispatch: () => {},
});

export function StoreProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
  });
  const value = { state, dispatch };

  return <Store.Provider value={value}>{children}</Store.Provider>;
}
