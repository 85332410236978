import * as React from "react";
import { useTranslation } from "react-i18next";

import "./styles/FeaturesHomeStyles.css";
import IconCards from "assets/icons/features_cards.png";
import IconCoins from "assets/icons/features_coins.png";
import IconSavings from "assets/icons/features_savings.png";
import IconWallet from "assets/icons/features_wallet.png";
// import FroinIso from "assets/logos/IsoFroinBlanco.png";
// import ColFlag from "assets/icons/col_flag.png";

function FeaturesHomeScreen() {
  const { t } = useTranslation();

  const features = [
    {
      image: IconCoins,
      name: "Money",
      title: "Canjea tu saldo",
      description:
        "Nuestra comunidad podrá canjear $FROIN por bonos de descuento para usar en nuestros comercios aliados",
      status: "new",
    },
    {
      image: IconSavings,
      name: "Earnings",
      title: "Participa y Gana",
      description:
        "Diseñamos diferentes dinámicas para que cualquier integrante de la comunidad pueda ganar $FROIN de varias maneras, tanto en nuestra plataforma web como en nuestras redes sociales de Instagram",
      status: "new",
    },
    {
      image: IconWallet,
      name: "Wallet",
      title: t("pages.home.featuresHome.weAreWallet"),
      description: t("pages.home.featuresHome.sendAndReceive"),
      status: "new",
    },

    {
      image: IconCards,
      name: "Cashless",
      title: t("pages.home.featuresHome.cashless"),
      description: (
        <>
          {t("pages.home.featuresHome.buyAndSell")}{" "}
          <a href="https://www.fravents.com">fravents.com</a>{" "}
          {t("pages.home.featuresHome.andReceive")}
        </>
      ),
      status: "soon",
      hide: true,
    },
    {
      image: IconCoins,
      name: "Money",
      title: t("pages.home.featuresHome.yourMoney"),
      description: (
        <>
          {t("pages.home.featuresHome.ifWishWithdraw")}{" "}
          <span>
            Mercado pago, Paypal {t("pages.home.featuresHome.or")} Criptomonedas
          </span>
          , {t("pages.home.featuresHome.canContact")}{" "}
          <a href="mailto:help@froin.co">help@froin.co</a>{" "}
          {t("pages.home.featuresHome.weWillGladly")}
        </>
      ),
      status: "soon",
      hide: true,
    },
    {
      image: IconSavings,
      name: "Savings",
      title: t("pages.home.featuresHome.investEarn"),
      description: t("pages.home.featuresHome.ourCommunity"),
      status: "soon",
      hide: true,
    },
  ];

  const renderFeatures = () =>
    features
      .filter((feature) => !feature.hide)
      .map((feature) => (
        <div className="item-grid" key={feature.name}>
          <img
            className="image-item-grid"
            src={feature.image}
            alt={feature.name}
          />
          <div className="content-item-grid">
            <h4 className="title-item-grid">{feature.title}</h4>
            <p className="description-item-grid">{feature.description}</p>
          </div>
          <div
            className={
              feature.status === "new"
                ? "new-item-grid"
                : "comming-soon-item-grid"
            }
          >
            {feature.status === "new"
              ? t("pages.home.featuresHome.new")
              : t("pages.home.featuresHome.comingSoon")}
          </div>
        </div>
      ));

  return (
    <div className="features-container">
      <h3 className="features-title">
        {t("pages.home.featuresHome.benefits")}
      </h3>
      <p className="features-description">
        {t("pages.home.featuresHome.atFroin")}
      </p>

      {/* <div className="features-exchange-currency">
        <div className="flag-exchange-currency">
          <img
            alt="Froin"
            src={FroinIso}
          />
        </div>
        <p className="label-exchange-currency">1{' '}<span>$FROIN</span></p>
        <div className="equal-exchange-currency">=</div>
        <p className="label-exchange-currency">30{' '}<span>$COP</span></p>
        <div className="flag-exchange-currency">
          <img
            alt="Cop"
            src={ColFlag}
          />
        </div>
      </div> */}

      <div className="features-grid">{renderFeatures()}</div>

      <div className="features-comming-soon-btn">
        {t("pages.home.featuresHome.moreBenefits")}
      </div>
    </div>
  );
}

export default FeaturesHomeScreen;
