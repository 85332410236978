import * as React from "react";
import { useState } from "react";

import "./styles/RegisterWebinarFormStyles.css";
import { verifyEmail } from "utils/validations";
import { TextInput } from "components";
import Model from "hooks/Model";
import * as moment from "moment";
import Api from "utils/api";

function RegisterArtistForm() {
  const [alias, setAlias] = useState("");
  const [email, setEmail] = useState("");
  const [link, setLink] = useState("");
  const [description, setDescription] = useState("");

  const onRegisterEmail = async (email: string) => {
    try {
      const currentTime = moment().format("x");

      Model.setData("loading", true);
      Model.setData("dialog", { open: false });

      const response = await Api.post("/manychat/registerPartner", {
        alias,
        email,
        link,
        description,
        created: currentTime,
        list: "register_artists",
      });

      if (response.status === 200) {
        setAlias("");
        setEmail("");
        setLink("");
        setDescription("");
        Model.updateAlerts({
          message:
            "Registramos tus datos éxistosamente, pronto te enviaremos un correo de respuesta.",
          variant: "success",
        });
      } else {
        throw response.data;
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || String(error),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onSubmit = () => {
    const formatedEmail = email.toLocaleLowerCase().replace(/\s/g, "");

    if (!email || !alias || !link || !description) {
      Model.updateAlerts({
        message: "Completa todos los datos para inscribirte",
        variant: "error",
      });
    } else if (!verifyEmail(formatedEmail)) {
      Model.updateAlerts({
        message: "Ingresa un correo válido para continuar",
        variant: "error",
      });
    } else {
      Model.setData("dialog", {
        open: true,
        title: `${alias}, Antes de continuar ...`,
        text: `¿Aceptas que utilicemos tu correo electrónico (${formatedEmail}) para contactarte sobre los detalles del proyecto FROIN? Puedes darte de baja en cualquier momento.`,
        txtLeft: "Cancelar",
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: "Acepto",
        fnRight: () => onRegisterEmail(formatedEmail),
      });
    }
  };

  return (
    <div className="register-container">
      <h3 className="register-title">Registro de Artistas</h3>
      <p className="register-description">
        🎟️ ¡Sé parte del futuro del entretenimiento con FROIN!
        <br />
        <br />
        🎤 Estamos abriendo un espacio exclusivo para artistas emergentes del
        mundo del entretenimiento que quieran sumarse a un proyecto
        revolucionario. El <b>único requisito</b> que debes tener es que manejes
        contenido en las plataformas{" "}
        <b>Youtube, SoundCloud, Mixcloud y Vimeo</b> mostrando tu talento para
        que lo impulsemos en nuestra plataforma.
        <br />
        <br />
        🚀 Los <b>beneficios que te traemos</b> son:
        <br />
        ✅ Impulsar tu presencia digital
        <br />
        ✅ Compartir tu contenido para generar mayor reproducciones
        <br />
        ✅ Generar $FROIN por la cantidad de tus visualizaciones
        <br />
        ✅ Utilizar tu saldo de diferentes maneras
        <br />
        ✅ Ser parte de una nueva comunidad que participará en nuevas dinámicas
        con beneficios exclusivos.
        <br />
        <br />
        🔥 Anímate a conocer el proyecto para ser parte de nuestro futuro, te
        resolveremos todas tus dudas.
        <br />
        <br />⏳ <b>¡Cupo limitado!</b> Pronto cerraremos las puertas a nuevas
        postulaciones. No te quedes fuera, deja tu datos y nuestro equipo te
        dará respuesta.
      </p>

      <div className="register-row-submit">
        <div className="cont-sub-row-submit">
          <div className="sub-row-submit">
            <TextInput
              value={alias}
              onChange={setAlias}
              placeholder="Nombre"
              customClassName="input-info"
            />
            <TextInput
              customClassName="input-info"
              value={email}
              onChange={setEmail}
              placeholder="Correo"
            />
            <TextInput
              value={link}
              onChange={setLink}
              placeholder="Link red social"
              customClassName="input-info"
            />
          </div>
          <TextInput
            value={description}
            onChange={setDescription}
            placeholder="Describe tu proyecto"
            customClassName="input-info"
          />
        </div>
        <button className="register-btn-submit" onClick={onSubmit}>
          Enviar registro
        </button>
      </div>
    </div>
  );
}

export default RegisterArtistForm;
