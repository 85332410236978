import { useEffect, useState } from "react";

import { usedImages } from "utils/usedImages";
import Model from "hooks/Model";

const PreLoadImagesService = () => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  const updateProgress = () => {
    setProgress((prev) => {
      const newValue = prev + 100 / usedImages.length;

      if (newValue > 100) return 100;

      return parseInt(newValue.toFixed(0));
    });
  };

  const preloadImages = async () => {
    try {
      const importantImages = usedImages.slice(0, 4);
      const noRelevantImages = usedImages.slice(4);

      Model.setData("loading", true);
      setLoading(true);

      await Promise.all(
        importantImages.map(
          (src) =>
            new Promise((resolve) => {
              const img = new Image();

              img.src = src;
              img.onload = () => {
                resolve(img);
                updateProgress();
              };
              img.onerror = () => {
                resolve(img);
                updateProgress();
              };
            })
        )
      );

      Promise.all(
        noRelevantImages.map(
          (src) =>
            new Promise((resolve) => {
              const img = new Image();

              img.src = src;
              img.onload = () => {
                resolve(img);
                updateProgress();
              };
              img.onerror = () => {
                resolve(img);
                updateProgress();
              };
            })
        )
      );
    } catch (error) {
      console.log("PreLoadImages error: ", error);
    } finally {
      Model.setData("showLogoLoader", true);
      setLoading(false);
    }
  };

  useEffect(() => {
    Model.setData("initialLoadProgress", progress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  useEffect(() => {
    preloadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
  };
};

export default PreLoadImagesService;
