import * as React from "react";
import { Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import SadImage from "assets/icons/sad.svg";
import { Layout } from "components";
import Model from "hooks/Model";
import pageStyles from "./styles";

const Page404 = () => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const location = useLocation();

  React.useEffect(() => {
    setTimeout(() => {
      Model.setData("loading", false);
    }, 1000);
    //eslint-disable-next-line
  }, []);

  return (
    <Layout withBg noheader>
      <div className={styles.container}>
        <img alt="Error 404" className={styles.img} src={SadImage} />
        <p className={styles.text}>
          {t("pages.page404.weDidntFindPage")}
          <br />
          <br />
          <code>{location.pathname}</code>
          <br />
          <br />
          <b>ERROR: </b>404
        </p>
        <Link href="/" className={styles.button}>
          {t("pages.page404.getMeOut")}
        </Link>
      </div>
    </Layout>
  );
};

export default Page404;
