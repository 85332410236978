import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  bannerAlert: {
    ...Commons.flexRow,
    backgroundColor: colors.yellow,
    position: "relative",
    zIndex: 1,
    color: colors.black,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    gap: 30,
    cursor: "pointer",
    borderRadius: "0 0 24px 24px",
    boxShadow: colors.shadowWhite,
    padding: "12px 0",
    marginBottom: -12,
    "& p": {
      margin: 0,
    },
    [Commons.smallQuery]: {
      marginBottom: -30,
      flexDirection: "column",
      gap: 6,
      fontSize: 14,
    },
  },
  rowTimeBannerAlert: {
    ...Commons.flexRow,
    alignItems: "center",
    justifyContent: "center",
    gap: 12,
  },
  labelTimeBannerAlert: {
    fontSize: 12,
    fontWeight: "lighter",
  },
  buttonBanner: {
    textDecoration: "none",
    color: colors.white,
    fontSize: 14,
    fontWeight: 500,
    "& div": {
      padding: "12px 24px",
      backgroundColor: colors.secondary,
      borderRadius: 12,
      boxShadow: colors.shadow,
    },
  },
  logo: {
    height: 72,
    position: "relative",
    display: "block",
    margin: "30px auto",
    [Commons.smallQuery]: {
      margin: "30px auto 24px auto",
      height: 42,
    },
  },
  backButton: {
    ...Commons.flexRow,
    ...Commons.defaultButton,
    backgroundColor: colors.orange,
    color: colors.white,
    fontSize: 24,
    borderRadius: 18,
    boxShadow: colors.shadowWhite,
    padding: "6px 60px",
    marginBottom: 60,
  },
  animatedOnDiv: {
    animation: "$opacityInit 1s linear",
    opacity: 1,
    position: "relative",
  },
  animatedOffDiv: {
    animation: "$opacityOut 1s linear",
    opacity: 0,
    position: "relative",
  },
  "@keyframes opacityInit": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  "@keyframes opacityOut": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
});

export default styles;
