import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "index.css";
import App from "pages";
import { ErrorBoundary } from "components/ErrorBoundary";
import reportWebVitals from "reportWebVitals";
import "languages/i18n";

const renderContent = () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </React.StrictMode>
  );
};

const initialFunction = () => {
  const isAllowed = sessionStorage.getItem("allow_test_user");
  const isTest = window?.location?.host?.includes?.("test.");

  if (isTest && isAllowed !== "allowed") {
    const code = prompt("Please enter the password", "...");
    if (code === "froin.life") {
      sessionStorage.setItem("allow_test_user", "allowed");
      renderContent();
    } else if (code != null) {
      alert("Error");
      initialFunction();
    }
  } else {
    renderContent();
  }
};

initialFunction();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
