import * as React from "react";
import { useEffect, useState } from "react";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";

import pageStyles from "./styles/ReceiveViewStyles";
import Model from "hooks/Model";
import { useChannel, useWallet } from "services";
import { returnFormatNumber } from "utils/validations";
import PlaceholderWin from "assets/images/avatar_win.png";
import { databaseService } from "utils/firebase";
import { useNavigate } from "react-router-dom";
import { RoutesPaths } from "pages/routes";
import ReactPlayer from "react-player";

function RedeemInitialView() {
  const styles = pageStyles();
  const navigate = useNavigate();

  const { currentBalance } = useWallet();
  const { recommendedVideos } = useChannel({
    channel: "froin",
  });

  const [allCoupons, setAllCoupons] = useState([]);

  const navigateToVideos = () => {
    navigate(RoutesPaths.profileVideos);
  };

  const navigateToRegisterCommerce = () => {
    navigate(`${RoutesPaths.home}?page=register-commerce`);
  };

  const navigateToReward = (id: string) => {
    navigate(`/detail-video?id=${id}`);
  };

  const renderRecommendedVideos = () =>
    recommendedVideos.slice(0, 3).map((video) => (
      <div key={video.id} className={styles.rowVideoRecommended}>
        <div className={styles.imgVideoRecommended}>
          {video?.imageUrl || !video?.url ? (
            <img src={video.imageUrl || PlaceholderWin} alt="Artista" />
          ) : (
            <ReactPlayer
              url={video?.url}
              light
              width="100%"
              height="100%"
              playing={false}
              playIcon={<></>}
            />
          )}
        </div>
        <div className={styles.infoVideoRecommended}>
          <p className={styles.nameVideoRecommended}>{video.name}</p>
          <p className={styles.prizeVideoRecommended}>
            {returnFormatNumber(video.limit)} FOINTS
          </p>
        </div>
        <button
          className={styles.buttonVideoRecommended}
          onClick={() => navigateToReward(video.id)}
        >
          Ganar
        </button>
      </div>
    ));

  const redeemCoupon = (id: string, isDisabled: boolean) => {
    if (isDisabled) {
      Model.updateAlerts({
        message:
          "Aún no logras completar la cantidad de $FROIN para adquirir este producto",
        variant: "error",
      });
    } else {
      Model.setData("dialog", {
        open: true,
        title: "Antes de continuar ...",
        text: `¡Felicitaciones! Has alcanzado el saldo para adquirir este producto, para continuar debes escribirnos un correo a help@froin.co detallando que desear redimir tu saldo por el producto con ID=${id}\n\nNuestro equipo te dará respuesta lo más pronto posible. Estamos trabajando para mejorar cada vez más la experiencia para automatizar procesos como estos.`,
        txtRight: "De acuerdo",
        fnRight: () => {
          Model.setData("dialog", { open: false });
        },
      });
    }
  };

  const renderCoupons = () => {
    const returnProgress = (amount) => {
      return (currentBalance / parseInt(amount)) * 100;
    };

    return allCoupons.map((coupon) => {
      const progress = returnProgress(coupon?.price);
      return (
        <div className={styles.cardCoupon} key={coupon.id}>
          <img
            className={styles.imgCardCoupon}
            alt="Partner"
            src={coupon?.image || PlaceholderWin}
          />
          <div className={styles.infoCardCoupon}>
            <p className={styles.labelCardCoupon}>{coupon?.name}</p>
            <div className={styles.containerProgressCardCoupon}>
              <div
                className={styles.progressCardCoupon}
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className={styles.priceCardCoupon}>
              {returnFormatNumber(coupon?.price)} $FROIN
            </p>
            <button
              className={[
                styles.buttonCardCoupon,
                progress < 100 ? styles.disabledButtonCardCoupon : "",
              ].join(" ")}
              onClick={() => redeemCoupon(coupon.id, progress < 100)}
            >
              Lo quiero
            </button>
          </div>
        </div>
      );
    });
  };

  const getCoupons = async () => {
    try {
      Model.setData("loading", true);
      let coupons = [];
      const couponsSnap = await databaseService.getDatabase("coupons");

      couponsSnap.forEach((snap) => {
        coupons.push({
          ...(snap.val() || []),
          id: snap.key,
        });
      });

      setAllCoupons(coupons.sort((a, b) => (b?.order || 0) - (a?.order || 0)));
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  useEffect(() => {
    getCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h2 className={styles.title}>Redimir 🎁</h2>

      <div className={styles.rowStats}>
        <div className={styles.colBalance}>
          <h3 className={styles.titleBalance}>Mi saldo</h3>
          <div className={styles.infoBalance}>
            <p className={styles.labelCurrenct}>$FROIN</p>
            <p className={styles.balance}>
              {returnFormatNumber(currentBalance, 0)}
            </p>
            <p className={styles.labelBalance}>Tu Balance</p>
            <p className={styles.descriptionBalance}>
              Gana apoyando artistas, redime por bonos de descuento, y disfruta
              de las experiencias que compartimos en FROIN
            </p>
          </div>
        </div>
        <div className={styles.colRecommended}>
          <h3 className={styles.labelRecommended}>
            Gana puntaje en el Ranking
          </h3>
          <div className={styles.listRecommended}>
            {renderRecommendedVideos()}
            <button
              className={styles.exploreVideoRecommended}
              onClick={navigateToVideos}
            >
              Explorar más contenido
            </button>
          </div>
        </div>
      </div>

      <div className={styles.containerCoupons}>
        <div className={styles.rowTitleCoupons}>
          <h3 className={styles.titleCoupons}>Usa tus $FROIN</h3>
          <div className={styles.rowNavigationCoupons}>
            <button className={styles.buttonNavigationCoupons}>
              <ChevronLeft />
            </button>
            <button className={styles.buttonNavigationCoupons}>
              <ChevronRight />
            </button>
          </div>
        </div>
        <div className={styles.rowItemsCoupons}>
          {renderCoupons()}
          <div className={styles.cardCoupon}>
            <div className={styles.soonCardCoupon}>
              <p>
                {"👀"} Próximamente anunciaremos más comercios {"🚀"}
              </p>

              <button
                className={styles.soonCardCoupon}
                onClick={navigateToRegisterCommerce}
              >
                Postular mi comercio
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RedeemInitialView;
