import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  headerContainer: {
    width: "100%",
    position: "relative",
    overflow: "hidden",
    height: 330,
    borderRadius: 50,
    animation: "$neonBgLoop 3s linear infinite",
    [Commons.smallQuery]: {
      height: 180,
      borderRadius: 24,
    },
  },
  headerImage: {
    ...Commons.coverBackground,
    width: "120%",
    position: "absolute",
    left: "-10%",
    aspectRatio: "3 / 1",
    [Commons.smallQuery]: {
      aspectRatio: "2 / 1",
    },
  },
  containerProfileImage: {
    ...Commons.flexCenter,
    position: "relative",
    backgroundColor: colors.black,
    borderRadius: "50%",
    width: 270,
    aspectRatio: "1 / 1",
    animation: "$neonBgLoop 3s linear infinite",
    margin: "-135px auto 0 auto",
    overflow: "hidden",
    [Commons.smallQuery]: {
      width: 120,
      margin: "-60px auto 0 auto",
    },
  },
  profileImage: {
    width: 120,
    aspectRatio: "1 / 1",
    [Commons.smallQuery]: {
      width: 60,
    },
  },
  bgWhite: {
    backgroundColor: colors.white,
    width: "100%",
    objectFit: "cover",
  },
  nameProfile: {
    position: "relative",
    color: colors.white,
    fontSize: 42,
    textAlign: "center",
    margin: "24px 0 42px 0",
    [Commons.smallQuery]: {
      fontSize: 30,
    },
  },
  descriptionProfile: {
    position: "relative",
    color: colors.white,
    fontSize: 24,
    textAlign: "center",
    whiteSpace: "break-spaces",
    boxShadow: `0 0 50px 3px ${colors.secondary}`,
    borderRadius: 24,
    margin: "0 0 60px 0",
    padding: 24,
    animation: "$neonBgLoop 6s linear infinite",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    [Commons.smallQuery]: {
      fontSize: 16,
    },
  },
  label: {
    color: colors.white,
    fontSize: 36,
    fontWeight: 600,
    margin: "0 0 18px 0",
    [Commons.smallQuery]: {
      fontSize: 30,
    },
  },
  containerListPlayer: {
    width: "100%",
    gap: 60,
    [Commons.smallQuery]: {
      gap: 18,
    },
  },
  itemPlayer: {
    aspectRatio: "1 / 1",
    position: "relative",
    borderRadius: 18,
    boxShadow: `0 0 30px 10px ${colors.orange}30`,
    overflow: "hidden",
    width: "calc((100% / 3) - 40px)",
    [Commons.smallQuery]: {
      width: "calc((100% / 2) - 12px)",
    },
  },
  iconPlayer: {
    position: "absolute",
    width: 90,
    height: 90,
    [Commons.smallQuery]: {
      width: 60,
      height: 60,
    },
  },
  overlayPlayer: {
    ...Commons.flexCenter,
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.75)",
    left: 0,
    top: 0,
    transition: "all 0.3s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.25)",
    },
  },
  imageVideo: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  newBadge: {
    position: "absolute",
    top: 12,
    left: 12,
    backgroundColor: colors.primary,
    color: colors.white,
    borderRadius: 12,
    padding: "6px 12px",
    fontSize: 18,
    boxShadow: colors.shadowWhite,
    fontWeight: 600,
    [Commons.smallQuery]: {
      fontSize: 12,
    },
  },
  "@keyframes neonBgLoop": {
    "0%": {
      boxShadow: `0 0 50px 3px ${colors.orange}30`,
    },
    "50%": {
      boxShadow: `0 0 50px 3px ${colors.orange}`,
    },
    "100%": {
      boxShadow: `0 0 50px 3px ${colors.orange}30`,
    },
  },
});

export default styles;
