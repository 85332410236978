import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  bannerContainer: {
    ...Commons.flexRow,
    backgroundImage: `linear-gradient(144deg, ${colors.black} 30%, ${colors.orange})`,
    minHeight: "50vh",
    width: "100%",
    borderRadius: 24,
    boxShadow: `0 0 60px 0px ${colors.white}50`,
    marginBottom: 60,
    animation: "$neonBgLoopWhite 3s linear infinite",
    boxSizing: "border-box",
    padding: 30,
    gap: 30,
    [Commons.midQuery]: {
      gap: 6,
      flexDirection: "column",
      height: "unset",
    },
  },
  columnBannerLeft: {
    ...Commons.flexCenter,
    flex: 1,
    maxWidth: "50%",
    position: "relative",
    height: "100%",
    [Commons.midQuery]: {
      height: "unset",
      maxWidth: "unset",
      flex: "unset",
    },
  },
  columnBanner: {
    flex: 2,
    maxWidth: "75%",
    display: "block",
    [Commons.midQuery]: {
      maxWidth: "unset",
      flex: "unset",
    },
  },
  containerImageLogo: {
    ...Commons.flexCenter,
    position: "absolute",
    width: "30vw",
    height: "auto",
    aspectRatio: "1 / 1",
    borderRadius: "50%",
    border: `6px solid ${colors.white}`,
    boxShadow: colors.shadowWhiteInset,
    padding: 60,
    backgroundColor: colors.black,
    boxSizing: "border-box",
    zIndex: 0,
    maxWidth: 360,
    [Commons.midQuery]: {
      maxWidth: 300,
      width: "90vw",
      position: "relative",
      padding: 90,
      marginBottom: 30,
    },
  },
  imageLogo: {
    width: "120%",
    height: "auto",
    aspectRatio: "1 / 1",
    position: "absolute",
  },
  overlayVideo: {
    position: "relative",
    width: "100%",
    height: "30vw",
    borderRadius: "50%",
    backgroundColor: "transparent",
    zIndex: 1,
    maxHeight: 360,
    [Commons.smallQuery]: {
      position: "absolute",
    },
  },
  titleBanner: {
    color: colors.white,
    fontWeight: "bold",
    fontSize: 48,
    margin: 0,
    [Commons.smallQuery]: {
      fontSize: 30,
    },
  },
  descriptionBanner: {
    color: colors.white,
    fontSize: 18,
    margin: "48px 0 30px 0",
    textAlign: "justify",
    whiteSpace: "break-spaces",
    [Commons.smallQuery]: {
      margin: "12px 0 30px 0",
      fontSize: 18,
    },
  },
  buttonBanner: {
    ...Commons.defaultButton,
    color: colors.white,
    fontWeight: 500,
    fontSize: 24,
    backgroundColor: colors.orange,
    borderRadius: 24,
    padding: "6px 24px",
    transition: "all 0.2s",
    boxShadow: colors.shadow,
    "&:hover": {
      boxShadow: `0 0 12px 0px ${colors.white}`,
    },
  },
  introDescriptionContainer: {
    color: colors.white,
    fontSize: 24,
    whiteSpace: "break-spaces",
    boxShadow: `0 0 50px 3px ${colors.secondary}`,
    borderRadius: 24,
    margin: "0 0 60px 0",
    padding: 24,
    animation: "$neonBgLoop 6s linear infinite",
    backgroundColor: "rgba(0 , 0, 0, 0.75)",
    textAlign: "justify",
    [Commons.smallQuery]: {
      fontSize: 18,
    },
  },
  rowButtons: {
    ...Commons.flexRow,
    gap: 24,
    marginBottom: 120,
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  buttonOption: {
    ...Commons.defaultButton,
    backgroundColor: colors.black,
    flex: 1,
    color: colors.white,
    fontWeight: 600,
    fontSize: 24,
    padding: "18px 6px",
    borderRadius: 18,
    boxShadow: colors.shadowWhite,
    border: `3px solid ${colors.orange}`,
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: colors.orange,
      borderColor: colors.white,
    },
    [Commons.smallQuery]: {
      width: "100%",
      boxSizing: "border-box",
    },
  },
  circleTransition: {
    ...Commons.centerAll,
    backgroundColor: colors.orange,
    borderRadius: "50%",
    position: "fixed",
    width: 0,
    height: 0,
    zIndex: 1,
    "&.show": {
      width: 30,
      height: 30,
      animation: "$inCircleScale 3s linear",
    },
  },
  "@keyframes inCircleScale": {
    "0%": {
      transform: "scale(0)",
    },
    "25%": {
      transform: "scale(100)",
    },
    "50%": {
      backgroundColor: colors.orange,
    },
    "100%": {
      backgroundColor: colors.black,
      transform: "scale(100)",
    },
  },
  "@keyframes neonBgLoop": {
    "0%": {
      boxShadow: `0 0 50px 3px ${colors.secondary}30`,
    },
    "50%": {
      boxShadow: `0 0 50px 3px ${colors.secondary}`,
    },
    "100%": {
      boxShadow: `0 0 50px 3px ${colors.secondary}30`,
    },
  },
  "@keyframes neonBgLoopWhite": {
    "0%": {
      boxShadow: `0 0 50px 3px ${colors.white}30`,
    },
    "50%": {
      boxShadow: `0 0 50px 3px ${colors.white}75`,
    },
    "100%": {
      boxShadow: `0 0 50px 3px ${colors.white}30`,
    },
  },
});

export default styles;
