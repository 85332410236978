import * as React from "react";

import { Header, Footer } from "components";
import pageStyles from "./styles";

interface LayoutProps {
  children: any;
  withBg?: boolean;
  noheader?: boolean;
  noFooter?: boolean;
  beforeToExit?: (callback: () => void) => () => void;
}

const Layout = ({
  children,
  withBg = false,
  noheader,
  noFooter,
  beforeToExit,
}: LayoutProps) => {
  const styles = pageStyles();

  return (
    <>
      <Header beforeToExit={beforeToExit} hide={noheader} />
      <div
        className={[
          styles.children,
          withBg ? styles.withBg : "",
          noheader ? styles.withoutHeader : "",
          noFooter ? styles.withFooter : "",
        ].join(" ")}
      >
        {children}
      </div>
      {noFooter ? <></> : <Footer />}
    </>
  );
};

export default Layout;
