import * as React from "react";
import { Layout } from "components";
import RedeemInitialView from "./RedeemInitialView";

function RedeemScreen() {
  return (
    <Layout withBg>
      <RedeemInitialView />
    </Layout>
  );
}

export default RedeemScreen;
