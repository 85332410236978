import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  title: {
    margin: 0,
    color: colors.orange,
    fontSize: 42,
    fontWeight: 600,
    [Commons.smallQuery]: {
      fontSize: 30,
    },
  },
  rowStats: {
    ...Commons.flexRow,
    gap: 30,
    alignItems: "start",
    marginTop: 30,
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  colBalance: {
    flex: 1,
  },
  titleBalance: {
    color: colors.white,
    fontSize: 24,
    margin: "0 0 24px 0",
    [Commons.smallQuery]: {
      fontSize: 18,
    },
  },
  infoBalance: {
    ...Commons.flexCenter,
    flexDirection: "column",
    backgroundColor: colors.white,
    borderRadius: 6,
    boxShadow: colors.shadowWhite,
    padding: 18,
    height: 240,
    [Commons.smallQuery]: {
      height: "unset",
    },
  },
  balance: {
    color: colors.secondary,
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 42,
    margin: 0,
    [Commons.smallQuery]: {
      fontSize: 36,
    },
  },
  labelCurrenct: {
    color: colors.secondary,
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 18,
    margin: 0,
    [Commons.smallQuery]: {
      fontSize: 14,
    },
  },
  labelBalance: {
    fontWeight: "600",
    textAlign: "center",
    fontSize: 18,
    margin: "42px 0 0 0",
    [Commons.smallQuery]: {
      fontSize: 16,
      margin: "12px 0 0 0",
    },
  },
  descriptionBalance: {
    textAlign: "center",
    fontSize: 12,
    margin: "6px 0 0 0",
  },
  colRecommended: {
    flex: 2,
  },
  labelRecommended: {
    color: colors.white,
    fontSize: 24,
    margin: "0 0 24px 0",
    [Commons.smallQuery]: {
      margin: "24px 0",
      fontSize: 18,
    },
  },
  listRecommended: {
    backgroundColor: colors.white,
    borderRadius: 6,
    boxShadow: colors.shadowWhite,
    padding: 18,
    height: 240,
    overflow: "auto",
    [Commons.smallQuery]: {
      height: "unset",
    },
  },
  containerCoupons: {
    marginTop: 60,
  },
  rowTitleCoupons: {
    ...Commons.flexCenter,
    margin: "0 0 24px 0",
  },
  titleCoupons: {
    color: colors.white,
    fontSize: 24,
    flex: 1,
    margin: 0,
    [Commons.smallQuery]: {
      fontSize: 18,
    },
  },
  rowNavigationCoupons: {
    ...Commons.flexRow,
    gap: 18,
  },
  buttonNavigationCoupons: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    backgroundColor: colors.white,
    boxShadow: colors.shadowWhite,
    width: 36,
    height: 36,
    borderRadius: "50%",
  },
  rowItemsCoupons: {
    whiteSpace: "nowrap",
    overflowY: "auto",
    marginBottom: 60,
  },
  cardCoupon: {
    display: "inline-block",
    position: "relative",
    margin: "30px 15px 0 15px",
    boxShadow: colors.shadowWhite,
    borderRadius: 12,
    overflow: "hidden",
    backgroundColor: colors.white,
    width: 300,
    [Commons.smallQuery]: {
      width: 240,
    },
  },
  imgCardCoupon: {
    width: "100%",
    height: 180,
    objectFit: "cover",
    backgroundColor: colors.gray50,
    [Commons.smallQuery]: {
      height: 150,
    },
  },
  infoCardCoupon: {
    padding: 12,
    whiteSpace: "wrap",
  },
  labelCardCoupon: {
    ...Commons.ellipsis,
    color: colors.black,
    fontSize: 18,
    margin: "12px 0",
    fontWeight: "bold",
    [Commons.smallQuery]: {
      fontSize: 16,
    },
  },
  containerProgressCardCoupon: {
    width: "100%",
    borderRadius: 18,
    height: 12,
    backgroundColor: colors.grayLight,
    overflow: "hidden",
  },
  progressCardCoupon: {
    height: "100%",
    backgroundColor: colors.primary,
    borderRadius: 18,
  },
  priceCardCoupon: {
    ...Commons.ellipsis,
    color: colors.primary,
    fontSize: 18,
    margin: "12px 0",
    fontWeight: "500",
    [Commons.smallQuery]: {
      fontSize: 16,
    },
  },
  buttonCardCoupon: {
    ...Commons.defaultButton,
    width: "100%",
    padding: "12px 0",
    textAlign: "center",
    color: colors.black,
    backgroundColor: colors.primary,
    borderRadius: 12,
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 12,
    [Commons.smallQuery]: {
      fontSize: 16,
    },
  },
  disabledButtonCardCoupon: {
    backgroundColor: colors.grayLight,
    color: colors.gray50,
    cursor: "no-drop",
  },
  soonCardCoupon: {
    ...Commons.flexCenter,
    flexDirection: "column",
    height: 370,
    width: "100%",
    backgroundColor: colors.yellow,
    whiteSpace: "wrap",
    "& p": {
      textAlign: "center",
      fontWeight: "bold",
      margin: "0 24px",
      fontSize: 18,
    },
    "& button": {
      ...Commons.defaultButton,
      padding: 12,
      textAlign: "center",
      color: colors.black,
      borderRadius: 12,
      fontSize: 16,
      fontWeight: "bold",
      height: "fit-content",
      width: "fit-content",
      marginTop: 30,
      backgroundColor: colors.white,
      [Commons.smallQuery]: {
        fontSize: 14,
      },
    },
    [Commons.smallQuery]: {
      height: 331,
    },
  },
  rowVideoRecommended: {
    ...Commons.flexRow,
    gap: 18,
    marginBottom: 12,
  },
  imgVideoRecommended: {
    width: 48,
    height: 48,
    borderRadius: "50%",
    overflow: "hidden",
    "& img": {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },
  },
  infoVideoRecommended: {
    flex: 1,
  },
  nameVideoRecommended: {
    margin: 0,
    fontWeight: "bold",
    [Commons.smallQuery]: {
      fontSize: 14,
    },
  },
  prizeVideoRecommended: {
    color: colors.primary,
    fontWeight: "500",
    margin: 0,
    fontSize: 16,
    [Commons.smallQuery]: {
      fontSize: 14,
    },
  },
  buttonVideoRecommended: {
    ...Commons.defaultButton,
    padding: 12,
    textAlign: "center",
    color: colors.black,
    borderRadius: 12,
    fontSize: 12,
    fontWeight: "bold",
    border: `1px solid ${colors.gray50}`,
    "&:hover": {
      backgroundColor: colors.primary,
    },
  },
  exploreVideoRecommended: {
    ...Commons.defaultButton,
    width: "100%",
    padding: "12px 0",
    textAlign: "center",
    color: colors.black,
    borderRadius: 12,
    fontSize: 16,
    fontWeight: "500",
    marginTop: 12,
    border: `1px solid ${colors.gray50}`,
    "&:hover": {
      backgroundColor: colors.orange,
    },
  },
});

export default styles;
