import { makeStyles } from "@mui/styles";
import { colors } from "styleguide";

const styles = makeStyles({
  container: {
    border: `6px solid ${colors.orange}`,
    borderRadius: "18px !important",
  },
  buttonAux: {
    color: `${colors.gray50} !important`,
    flex: 1,
  },
  buttonCancel: {
    color: `${colors.orange} !important`,
    flex: 1,
  },
  buttonDone: {
    backgroundColor: `${colors.orange} !important`,
    color: `${colors.white} !important`,
    flex: 1,
  },
});

export default styles;
