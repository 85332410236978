import * as React from "react";
import { useState } from "react";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useNavigate } from "react-router-dom";

import LogoFroin from "assets/logos/LogoFroinBlanco.png";
import { ElementsParallax, Layout } from "components";
import pageStyles from "./styles/welcomeStyles";
import Model from "hooks/Model";
import { analyticsService } from "utils/firebase";

import RegisterArtistForm from "./RegisterArtistForm";
import RegisterCommerceForm from "./RegisterCommerceForm";
import RegisterWebinarForm from "./RegisterWebinarForm";
import BannerHome from "../home/BannerHome";
import RolesInfo from "../home/RolesInfo";
import FeaturesHome from "../home/FeaturesHome";
import detectIncognito from "detectincognitojs";
import LocalStorage from "utils/localStorage";
import { ChevronLeft } from "@mui/icons-material";

// const useCountDownController = ({ dateTo }: { dateTo: Moment }) => {
//   const [currentTime, setCurrentTime] = useState({
//     days: 0,
//     hours: 0,
//     minutes: 0,
//     seconds: 0,
//   });

//   const updateDate = () =>
//     setTimeout(() => {
//       const days = moment(dateTo).diff(moment(), "days");
//       const hours = moment(dateTo).diff(moment(), "hours") - days * 24;
//       const minutes =
//         moment(dateTo).diff(moment(), "minutes") - days * 24 * 60 - hours * 60;
//       const seconds =
//         moment(dateTo).diff(moment(), "seconds") -
//         days * 24 * 60 * 60 -
//         hours * 60 * 60 -
//         minutes * 60;

//       setCurrentTime({ days, hours, minutes, seconds });
//     }, 1000);

//   useEffect(() => {
//     const callback = updateDate();

//     return () => clearTimeout(callback);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [currentTime]);

//   return currentTime;
// };

const Welcome = () => {
  const navigate = useNavigate();
  // const countDown = useCountDownController({
  //   dateTo: moment("20/03/2025 18:00", "DD/MM/YYYY HH:mm"),
  // });

  const urlParams = new URLSearchParams(window.location.search);

  const styles = pageStyles();
  const [currentView, setCurrentView] = useState(
    urlParams.get("page") || "intro"
  );
  const [animationOpacity, setAnimationOpacity] = useState("on");
  // const [showCountdown, setShowCountdown] = useState(false);
  // const finishedCountdown = useMemo(
  //   () =>
  //     countDown.days < 1 &&
  //     countDown.hours < 1 &&
  //     countDown.minutes < 1 &&
  //     countDown.seconds < 1 &&
  //     showCountdown,
  //   [countDown, showCountdown]
  // );

  const saveCompleteWelcome = async (callback: () => void) => {
    try {
      const result = await detectIncognito();

      LocalStorage(result.isPrivate).setItem("completedWelcome", "true");
      Model.setData("completedWelcome", true);
      callback();
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    }
  };

  const changeView = (view: string) => {
    setAnimationOpacity("off");

    setTimeout(() => {
      setCurrentView(view);
      setAnimationOpacity("on");
      window.scrollTo(0, 0);
      analyticsService.logEvent("page_view", { page_title: view });
    }, 1000);
  };

  const renderView = () => {
    switch (currentView) {
      case "roles":
        return (
          <div
            className={
              animationOpacity === "on"
                ? styles.animatedOnDiv
                : styles.animatedOffDiv
            }
          >
            <RolesInfo
              isIntro
              callback={(view?: string) => changeView(view || "register")}
              BackComponent={({ available, callback }) => (
                <button
                  className={styles.backButton}
                  onClick={available ? () => changeView("intro") : callback}
                >
                  <ChevronLeft fontSize="large" />
                  <span>Volver</span>
                </button>
              )}
            />
          </div>
        );
      case "benefits":
        return (
          <div
            className={
              animationOpacity === "on"
                ? styles.animatedOnDiv
                : styles.animatedOffDiv
            }
          >
            <FeaturesHome />
            <button
              className={styles.backButton}
              onClick={() => changeView("intro")}
            >
              <ChevronLeft fontSize="large" />
              <span>Volver</span>
            </button>
          </div>
        );
      case "register":
        return (
          <div
            className={
              animationOpacity === "on"
                ? styles.animatedOnDiv
                : styles.animatedOffDiv
            }
          >
            <RegisterWebinarForm />
            <button
              className={styles.backButton}
              onClick={() => changeView("intro")}
            >
              <ChevronLeft fontSize="large" />
              <span>Volver</span>
            </button>
          </div>
        );
      case "register-commerce":
        return (
          <div
            className={
              animationOpacity === "on"
                ? styles.animatedOnDiv
                : styles.animatedOffDiv
            }
          >
            <RegisterCommerceForm />
            <button
              className={styles.backButton}
              onClick={() => changeView("intro")}
            >
              <ChevronLeft fontSize="large" />
              <span>Volver</span>
            </button>
          </div>
        );
      case "register-artist":
        return (
          <div
            className={
              animationOpacity === "on"
                ? styles.animatedOnDiv
                : styles.animatedOffDiv
            }
          >
            <RegisterArtistForm />
            <button
              className={styles.backButton}
              onClick={() => changeView("intro")}
            >
              <ChevronLeft fontSize="large" />
              <span>Volver</span>
            </button>
          </div>
        );
      case "intro":
      default:
        return (
          <div
            className={
              animationOpacity === "on"
                ? styles.animatedOnDiv
                : styles.animatedOffDiv
            }
          >
            <BannerHome showButtons onPressButton={changeView} />
          </div>
        );
    }
  };

  // const CountDown = () =>
  //   showCountdown ? (
  //     <div
  //       className={styles.bannerAlert}
  //       onClick={finishedCountdown ? () => {} : () => changeView("register")}
  //     >
  //       <p>
  //         🚀 Lanzamiento plataforma 🚀{" "}
  //         {finishedCountdown
  //           ? "Estamos en vivo"
  //           : "y próxima charla informativa en"}
  //       </p>
  //       {finishedCountdown ? (
  //         <a
  //           className={styles.buttonBanner}
  //           href="https://meet.google.com/vsc-dext-osi"
  //         >
  //           <div>Únete a la charla aquí</div>
  //         </a>
  //       ) : (
  //         <div className={styles.rowTimeBannerAlert}>
  //           <div>
  //             <p>{countDown.days}</p>
  //             <p className={styles.labelTimeBannerAlert}>Días</p>
  //           </div>
  //           <div>
  //             <p>{countDown.hours}</p>
  //             <p className={styles.labelTimeBannerAlert}>Horas</p>
  //           </div>
  //           <div>
  //             <p>{countDown.minutes}</p>
  //             <p className={styles.labelTimeBannerAlert}>Minutos</p>
  //           </div>
  //           <div>
  //             <p>{countDown.seconds}</p>
  //             <p className={styles.labelTimeBannerAlert}>Segundos</p>
  //           </div>
  //         </div>
  //       )}
  //       <p>
  //         {finishedCountdown
  //           ? "Gana recompensas por participar"
  //           : "¡Inscríbete aquí y accede a los beneficios!"}
  //       </p>
  //     </div>
  //   ) : (
  //     <></>
  //   );

  React.useEffect(() => {
    if (currentView === "recommended") {
      saveCompleteWelcome(() => navigate("/profile-videos"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView]);

  React.useEffect(() => {
    Model.setData("loading", false);
    Model.setData("opacity", 0.7);
    // setTimeout(() => {
    //   setShowCountdown(true);
    // }, 1000);
  }, []);

  return (
    <Layout withBg noheader noFooter>
      <ElementsParallax variant="leftBg" />
      <ElementsParallax variant="rightBg" />
      <div style={{ height: 60 }} />
      {renderView()}
      <img alt="FROIN" src={LogoFroin} className={styles.logo} />
      <div style={{ height: 90 }} />
    </Layout>
  );
};

export default Welcome;
